@if (showMenuFilter()) {
  <menu-filters
    [currentLocation]="currentLocation()"
    [lang]="lang()"
    [languages]="languages()"
    [params]="params()"
    [locations]="locations()"
    [organisationUsers]="organisationUsers()"
    [templates]="templates()"
    [user]="user()"
    (chooseFilter)="chooseFilter.emit($event)"
  ></menu-filters>
} @else {
  @if (actionName() === 'createDietMenu') {
    <create-diet
      [diets]="diets()"
      [lang]="lang()"
      [locationId]="menu().location"
      (cancelAction)="cancelAction.emit()"
      (createNewDiet)="createNewDiet.emit($event)"
    ></create-diet>
  }
  @if (actionName() === 'generateArchive') {
    <generate-archive
      (cancelAction)="cancelAction.emit()"
      (createArchive)="createArchive.emit($event)"
    >
    </generate-archive>
  }
  @if (actionName() === 'generateTypeArchive') {
    <generate-archive
      [showOrdertaking]="true"
      (cancelAction)="cancelAction.emit()"
      (createArchive)="createArchive.emit($event)"
    >
    </generate-archive>
  }
  @if (actionName() === 'deleteChildren') {
    <delete-children
      [deleteChildrenList]="deleteChildrenList()"
      (cancelAction)="cancelAction.emit()"
      (deleteChildrenMenu)="deleteChildrenMenu.emit()"
    >
    </delete-children>
  }
  @if (actionName() === 'deleteMenu') {
    <delete-menu
      [deleteMenuWarning]="deleteMenuWarning()"
      (cancelAction)="cancelAction.emit()"
      (deleteSelectedMenu)="deleteSelectedMenu.emit()"
    >
    </delete-menu>
  }
  @if (actionName() === 'copyWithOptions') {
    <copy-with-options
      [allergens]="allergens()"
      [hasModuleAllergens]="hasModuleAllergens()"
      [menu]="menu()"
      [lang]="lang()"
      (cancelAction)="cancelAction.emit()"
      (copyMenuWithOptions)="copyMenuWithOptions.emit($event)"
    >
    </copy-with-options>
  }
  @if (actionName() === 'regenerateMenus') {
    <regenerate-menu
      (cancelAction)="cancelAction.emit()"
      (regenerateMenusActions)="regenerateMenusActions.emit($event)"
    ></regenerate-menu>
  }
  @if (actionName() === 'refreshSubMenu') {
    <refresh-sub-menu
      (cancelAction)="cancelAction.emit()"
      (refreshSubMenuActions)="refreshSubMenuActions.emit($event)"
    >
    </refresh-sub-menu>
  }
  @if (actionName() === 'shareMenu') {
    <app-share-menu
      [user]="user()"
      [locations]="locations()"
      [menuName]="menuName()"
      [menuCreator]="menuCreator()"
      [menuDate]="menuDate()"
      [menuLocation]="menuLocation()"
      (cancelAction)="cancelAction.emit()"
      (shareMenuAction)="shareMenuAction.emit($event)"
    >
    </app-share-menu>
  }
}
