import { AsyncPipe, formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  inject,
  viewChild,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SidePanelControllerBase } from 'src/app/shared/Classes/side-panel-controller.base';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  TaskType,
  CopyAsOptions,
  CopyMenuOptions,
  GenerateArchiveOptions,
  MenusOverviewParams,
  OverviewMenu,
  ShareMenuDetail,
} from 'src/app/shared/Models/menu';
import {
  createArchive,
  fetchDiets,
  fetchRules,
  fetchTypes,
  fetchUsedTemplates,
  setCurrentLocation,
  showPreviewDialog,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import {
  EventBusService,
  SharedEventTypes,
} from 'src/app/shared/Services/event-bus/event-bus.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { fetchOrganisationUsers } from 'src/app/shared/user/ngrx/user.actions';
import {
  selectBlockStatusMessage,
  selectIsAdministrator,
  selectMainStatus,
  selectOrganisationUsers,
  selectUser,
  selectUserPrivileges,
} from 'src/app/shared/user/ngrx/user.selectors';
import { identity, isEmpty, isNil, omitBy, pickBy } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  changeMenu,
  clearMenus,
  deleteChildren,
  deleteMenu,
  duplicateMenu,
  fetchMenus,
  refreshSubMenu,
  regenerateMenus,
  shareMenu,
} from './ngrx/menus-overview.actions';
import {
  selectIsTableDataLoading,
  selectMenus,
} from './ngrx/menus-overview.selectors';
import { TranslocoPipe } from '@jsverse/transloco';
import { ViewAsLocationComponent } from '../../shared/Components/view-as-location/view-as-location.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SpinnerComponent } from '../../shared/Components/spinner/spinner.component';
import { MenuOverviewSidebarComponent } from './menu-overview-sidebar/menu-overview-sidebar.component';
import { ReturnSidePanelMobileComponent } from '../../shared/Components/return-side-panel-mobile/return-side-panel-mobile.component';
import { MenuOverviewTableComponent } from './menu-overview-table/menu-overview-table.component';
import { sharedFeature } from 'src/app/shared/ngrx/shared.state';

@Component({
  selector: 'menus-overview-container',
  templateUrl: './menus-overview.container.html',
  styleUrls: ['./menus-overview.container.scss'],
  imports: [
    MatButtonModule,
    MenuOverviewTableComponent,
    ReturnSidePanelMobileComponent,
    MenuOverviewSidebarComponent,
    SpinnerComponent,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ViewAsLocationComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class MenusOverviewContainer
  extends SidePanelControllerBase
  implements AfterViewInit, OnInit, OnDestroy
{
  private activateRoute = inject(ActivatedRoute);
  private dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private eventBus = inject(EventBusService);
  private router = inject(Router);
  private utils = inject(UtilsService);

  copyAsEvent: {
    rule: number;
    enable_ordertaking?: boolean;
    create_archive?: boolean;
  };
  currentTaskType: TaskType;
  currentLocation: number = null;
  currentMenu: OverviewMenu;
  currentRulesetId: number;
  deleteChildrenList: string[];
  deleteMenuWarning: string;
  private destroyed$ = new Subject<void>();
  hasModuleAllergens: boolean;
  hasModuleOrders: boolean;
  isMobileView: boolean;
  lang: InterfaceLanguage;
  lastArchiveReady: boolean;
  menuCreator: boolean;
  menuDate: string;
  menuName: string;
  menuLocation: number;
  menuToCopy: {
    date?: string;
    menu?: OverviewMenu;
    options?: Partial<CopyMenuOptions>;
  };
  params: Partial<MenusOverviewParams>;
  regenerateContent: string;
  regenerateTitle: string;
  selectedAction: string;
  showMenuFilter = true;
  showSpinner = false;

  allergens$ = this.ngrxStore.select(sharedFeature.selectAllergens);
  consumerTypes$ = this.ngrxStore.select(sharedFeature.selectTypes);
  diets$ = this.ngrxStore.select(sharedFeature.selectDiets);
  isAdministrator$ = this.ngrxStore.select(selectIsAdministrator);
  isTableDataLoading$ = this.ngrxStore.select(selectIsTableDataLoading);
  locations$ = this.ngrxStore.select(sharedFeature.selectAllLocations);
  menus$ = this.ngrxStore.select(selectMenus);
  organisationUsers$ = this.ngrxStore.select(selectOrganisationUsers);
  privileges$ = this.ngrxStore.select(selectUserPrivileges);
  rulesets$ = this.ngrxStore.select(sharedFeature.selectSharedRulesets);
  usedTemplates$ = this.ngrxStore.select(sharedFeature.selectTemplatesUsed);
  user$ = this.ngrxStore.select(selectUser);
  userStatus$ = this.ngrxStore.select(selectMainStatus);
  userStatusBlockMessage$ = this.ngrxStore.select(selectBlockStatusMessage);

  readonly picker = viewChild<MatDatepicker<Date>>('picker');

  ngAfterViewInit(): void {
    this.sidePanel.topOffsetMobile = 0;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activateRoute.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.handleQueryParams(params);
      });
    this.utils.reloadMenus
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() =>
        this.ngrxStore.dispatch(fetchMenus({ query: this.params })),
      );
    this.sidePanel.mobileViewSubject
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => (this.isMobileView = value));
    this.utils.getLang((lang) => (this.lang = lang));

    const usedTemplateParams = {
      condensed: true,
      used: true,
      ordering: this.lang,
    };
    this.ngrxStore.dispatch(fetchUsedTemplates({ params: usedTemplateParams }));
    if (this.hasModule('auto'))
      this.ngrxStore.dispatch(fetchRules({ params: {} }));
    if (this.hasModule('order'))
      this.ngrxStore.dispatch(fetchTypes({ params: {} }));
    if (this.hasModule('diet'))
      this.ngrxStore.dispatch(fetchDiets({ params: {} }));
    if (this.hasModule('loca')) {
      this.ngrxStore.dispatch(
        fetchOrganisationUsers({
          params: { condensed: true, is_active: true },
        }),
      );
    }
    this.hasModuleAllergens = this.hasModule('all');
    this.hasModuleOrders = this.hasModule('order');
  }

  cancelAction(): void {
    this.showMenuFilter = true;
    this.currentMenu = null;
    this.hideSidePanel();
  }

  changeMenu({
    menu,
    featured,
  }: {
    menu: OverviewMenu;
    featured: boolean;
  }): void {
    this.ngrxStore.dispatch(changeMenu({ menu, data: { featured } }));
  }

  changePagination = (event: { page: number; pageSize: number }): void => {
    const { page, pageSize } = event;
    const pageParams = { page_size: pageSize };
    if (page !== null) {
      pageParams['page'] = page;
    } else {
      delete this.params['page'];
    }
    this.router.navigate(['/menus'], {
      queryParams: { ...this.params, ...pageParams },
    });
  };

  chooseFilter(params?: Partial<MenusOverviewParams>): void {
    const checkParams = isEmpty(omitBy(params, isNil));
    delete this.params['page'];
    this.router.navigate(['/menus'], {
      queryParams: checkParams ? this.params : params,
    });
  }

  copyAsAction(options: CopyAsOptions): void {
    this.showSpinner = true;
    this.ngrxStore.dispatch(
      duplicateMenu({
        id: this.currentMenu.id,
        date: null,
        diets: [],
        rule: this.copyAsEvent.rule,
        options,
        redirectStep: 4,
      }),
    );
  }

  showMessage(data: { message: string }): void {
    this.ngrxStore.dispatch(showSnackbarMessage({ message: data.message }));
  }

  copyAsDiet = (menu: OverviewMenu): void => {
    this.triggerAction('createDietMenu', menu);
  };

  copyMenu = ({ value }: { value: Date }): void => {
    this.showSpinner = true;
    const date: string = value
      ? this.dateAdapter.format(value, 'yyyy-MM-dd')
      : null;
    this.menuToCopy &&
      this.ngrxStore.dispatch(
        duplicateMenu({
          id: this.menuToCopy.menu.id,
          date,
          redirectStep: 1,
        }),
      );
  };

  copyMenuWithOptions(options: CopyMenuOptions): void {
    if (!this.currentMenu) return;
    this.showSpinner = true;
    this.ngrxStore.dispatch(
      duplicateMenu({
        id: this.currentMenu.id,
        diets: null,
        rule: null,
        options,
        redirectStep: 1,
      }),
    );
    this.showMenuFilter = true;
  }

  copyWithOptions = (menu: OverviewMenu): void => {
    this.triggerAction('copyWithOptions', menu);
  };

  createArchive(options: GenerateArchiveOptions): void {
    if (!this.currentMenu) return;
    if (this.currentRulesetId) options['ruleset'] = this.currentRulesetId;
    this.ngrxStore.dispatch(
      createArchive({
        menu: this.currentMenu,
        task_type: this.currentTaskType,
        options,
      }),
    );
    this.showMenuFilter = true;
  }

  createBtnClicked(data: { btn: MatButton; tooltip: MatTooltip }): void {
    this.utils.createBtnMessage(data.btn, data.tooltip);
  }

  createNewDiet(diets: number[]): void {
    if (!this.currentMenu) return;
    this.showSpinner = true;
    this.ngrxStore.dispatch(
      duplicateMenu({
        id: this.currentMenu.id,
        date: null,
        diets: diets,
        redirectStep: 4,
      }),
    );
  }

  deleteChildren(menu: OverviewMenu): void {
    if (menu.children.length === 0) return;
    this.triggerAction('deleteChildren', menu);
    const details = [];
    for (const child of menu.children) {
      details.push(child.name ? child.name : '-');
    }
    this.deleteChildrenList = details;
  }

  deleteChildrenMenu(): void {
    this.ngrxStore.dispatch(
      deleteChildren({ id: this.currentMenu.id, query: this.params }),
    );
    this.showMenuFilter = true;
  }

  deleteMenu(menu: OverviewMenu): void {
    this.triggerAction('deleteMenu', menu);
    this.getMenuName(menu);
    this.deleteMenuWarning =
      this.menuName || formatDate(this.menuDate, 'shortDate', this.lang);
  }

  deleteSelectedMenu(): void {
    this.ngrxStore.dispatch(
      deleteMenu({ id: this.currentMenu.id, query: this.params }),
    );
    this.showMenuFilter = true;
    if (this.isMobileView) this.hideSidePanel();
  }

  createChildMenu = ({
    menu,
    data,
  }: {
    menu: OverviewMenu;
    data: { id: number; entity: 'type' | 'rule' };
  }): void => {
    this.showSpinner = true;
    this.ngrxStore.dispatch(
      duplicateMenu({
        id: menu.id,
        [data.entity === 'type' ? 'consumerType' : data.entity]: data.id,
        redirectStep: 4,
      }),
    );
  };

  generateArchive = ({
    menu,
    rulesetId = null,
    taskType,
  }: {
    menu: OverviewMenu;
    rulesetId?: number;
    taskType: TaskType;
  }): void => {
    this.triggerAction(
      rulesetId ? 'generateArchive' : 'generateTypeArchive',
      menu,
    );
    this.currentRulesetId = rulesetId;
    this.currentTaskType = taskType;
  };

  getMenuName(menu: OverviewMenu): void {
    this.menuName = menu.name;
    this.menuDate = formatDate(new Date(menu.date), 'shortDate', this.lang);
    this.menuLocation = menu.location;
    this.menuCreator = menu.created_by_role === 3;
  }

  handleQueryParams = (params: Params): void => {
    if (params && Object.entries(params).length) {
      this.ngrxStore.dispatch(fetchMenus({ query: { ...params } }));
    }
    this.params = {
      page_size: params && (parseInt(params.page_size, 10) || 20),
      date_from: params?.date_from,
      date_to: params?.date_to,
      date_range: params?.date_range,
      name: params?.name,
      hide_location_menus: params?.hide_location_menus,
      ...params,
    };
    ['onboarding_template', 'location', 'location_group', 'created_by']
      .filter((f) => parseInt(params[f], 10))
      .forEach((f) => {
        this.params[f] = parseInt(params[f], 10);
      });
    this.params = pickBy(this.params, identity);
  };

  hasModule(code: string): boolean {
    return this.utils.hasModules(code);
  }

  locationChanged({ value: id }: MatSelectChange): void {
    this.currentLocation = id;
    this.ngrxStore.dispatch(setCurrentLocation({ currentLocation: id }));
    this.ngrxStore.dispatch(fetchMenus({ query: this.params }));
    if (this.hasModule('auto'))
      this.ngrxStore.dispatch(fetchRules({ params: {} }));
  }

  openCreateMenu(): void {
    this.eventBus.emit({ type: SharedEventTypes.TriggerCreateMenuButton });
  }

  previewMenu = (menu: OverviewMenu): void => {
    const previewData = this.utils.menuToPreviewData(menu, {
      print_mode: true,
    });
    this.ngrxStore.dispatch(
      showPreviewDialog({ htmlPreview: true, ...previewData }),
    );
  };

  regenerateMenus(menu: OverviewMenu): void {
    this.triggerAction('regenerateMenus', menu);
  }

  regenerateMenusActions(): void {
    this.ngrxStore.dispatch(
      regenerateMenus({ menu: this.currentMenu, query: this.params }),
    );
    this.showMenuFilter = true;
  }

  refreshSubMenu(menu: OverviewMenu): void {
    this.triggerAction('refreshSubMenu', menu);
  }

  refreshSubMenuActions(): void {
    this.ngrxStore.dispatch(refreshSubMenu({ menu: this.currentMenu }));
    this.showMenuFilter = true;
  }

  shareMenu(menu: OverviewMenu): void {
    this.triggerAction('shareMenu', menu);
    this.getMenuName(menu);
  }

  shareMenuAction(shareMenuData: ShareMenuDetail): void {
    this.ngrxStore.dispatch(
      shareMenu({
        url: this.currentMenu.url,
        shareMenuData,
        query: this.params,
      }),
    );
    this.showMenuFilter = true;
    this.currentMenu = null;
    if (this.isMobileView) this.showSidePanel();
  }

  showCopyMenu = (menu: OverviewMenu): void => {
    this.picker().open();
    this.menuToCopy = {
      menu,
    };
  };

  showPlanLimit(status): boolean {
    if (status) {
      const usageLimits = status.usage_limits;
      if (status.subscription_oneoff && usageLimits.limit_edits_per_month > 0)
        return true;
      if (
        (status.subscription_active ||
          (status.trial_period &&
            new Date(status.inactive_on) > this.dateAdapter.today())) &&
        (usageLimits.limit_menus_per_month > 0 ||
          usageLimits.limit_templates_per_month)
      )
        return true;
    }
    return false;
  }

  triggerAction(action: string, menu: OverviewMenu): void {
    this.selectedAction = action;
    this.showMenuFilter = false;
    this.currentMenu = menu;
    if (this.isMobileView) this.showSidePanel();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.ngrxStore.dispatch(clearMenus());
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
