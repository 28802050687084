import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  ReactiveFormsModule,
} from '@angular/forms';
import { ShareMenuDetail } from 'src/app/shared/Models/menu';
import { SimpleLocation } from 'src/app/shared/Models/location';
import { User } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MtTooltipDirective } from '../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-share-menu',
  templateUrl: './share-menu.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./share-menu.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    MatCheckboxModule,
    MatIconModule,
    MtTooltipDirective,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class ShareMenuComponent implements OnChanges {
  private formBuilder = inject(FormBuilder);

  readonly locations = input<SimpleLocation[]>(undefined);
  readonly menuDate = input<string>(undefined);
  readonly menuName = input<string>(undefined);
  readonly menuLocation = input<number>(undefined);
  readonly menuCreator = input<boolean>(undefined);
  readonly user = input<User>(undefined);
  readonly cancelAction = output<void>();
  readonly shareMenuAction = output<ShareMenuDetail>();

  form: FormGroup<{
    share_with_organisation: FormControl<boolean>;
    locations: FormArray<FormControl<boolean>>;
    copy_recipes: FormControl<boolean>;
    copy_diets: FormControl<boolean>;
    overwrite_data: FormControl<boolean>;
  }>;

  get locationsFormArray() {
    return this.form?.controls.locations;
  }

  ngOnChanges(changes: SimpleChanges) {
    const locations = this.locations();
    if (
      ('locations' in changes && locations) ||
      'menuLocation' in changes ||
      'menuCreator' in changes
    ) {
      // Clear this.locationsFormArray
      if (this.locationsFormArray) this.locationsFormArray.clear();
      if (this.form) this.form = undefined;

      const isMenuLocationNull = this.menuLocation() === null;
      this.initForm(isMenuLocationNull);
      if (!isMenuLocationNull) {
        this.initFormListeners();
      }

      this.addCheckboxes();
    }
  }

  private addCheckboxes() {
    const locations = this.locations();
    locations.forEach((location) => {
      const isDisabled =
        location.id === this.menuLocation() && this.menuCreator() === false;
      this.locationsFormArray.push(
        new FormControl({ value: false, disabled: isDisabled }),
      );
    });
  }

  private initForm(isMenuLocationNull: boolean): void {
    this.form = this.formBuilder.group({
      share_with_organisation: new FormControl<boolean>({
        value: false,
        disabled: isMenuLocationNull,
      }),
      locations: new FormArray<FormControl<boolean>>(
        [],
        this.minSelectedCheckboxesOrOrganisation(1),
      ),
      copy_recipes: new FormControl<boolean>(false),
      copy_diets: new FormControl<boolean>(false),
      overwrite_data: new FormControl<boolean>(false),
    });
  }

  private initFormListeners(): void {
    const locations = this.locations();

    // Flag to prevent infinite loops between listeners
    let updatingControls = false;

    // Set up a listener for the share_with_organisation checkbox
    this.form.controls.share_with_organisation.valueChanges.subscribe(
      (checked) => {
        if (updatingControls) return;

        updatingControls = true;
        if (checked) {
          this.locationsFormArray.controls.forEach((control) => {
            control.disable();
          });
        } else {
          this.locationsFormArray.controls.forEach((control, i) => {
            const location = locations[i];
            const isDisabled =
              location.id === this.menuLocation() &&
              this.menuCreator() === false;
            if (!isDisabled) {
              control.enable();
            }
          });
        }
        updatingControls = false;
      },
    );

    // Set up a listener for location checkboxes
    this.locationsFormArray.valueChanges.subscribe((values) => {
      if (updatingControls) return;

      updatingControls = true;
      const anySelected = values.some((value) => value === true);
      if (anySelected) {
        this.form.controls.share_with_organisation.disable();
      } else {
        this.form.controls.share_with_organisation.enable();
      }
      updatingControls = false;
    });
  }

  private minSelectedCheckboxesOrOrganisation(min = 1): ValidatorFn {
    const validator: ValidatorFn = (
      formArray: FormArray<FormControl<boolean>>,
    ) => {
      // Skip validation if share_with_organisation is checked
      if (this.form?.controls.share_with_organisation.value) {
        return null;
      }

      const totalSelected = formArray.controls
        .map((ctr) => +ctr.value)
        .reduce((prev, next) => (next ? prev + next : prev), 0);
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  submit(): void {
    const locations = this.locations();

    // If sharing with organisation, use empty locations array, else use selected locations
    let selectedLocations: number[] = [];
    if (!this.form.controls.share_with_organisation.value) {
      selectedLocations = this.form.value.locations
        .map((checked, i) => (checked ? locations[i].id : null))
        .filter((v) => v !== null);
    }
    const shareMenuData: ShareMenuDetail = {
      locations: selectedLocations,
      copy_recipes: this.form.controls.copy_recipes.value,
      copy_diets: this.form.controls.copy_diets.value,
      overwrite_data: this.form.controls.overwrite_data.value,
    };
    this.shareMenuAction.emit(shareMenuData);
  }
}
